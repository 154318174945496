<!-- 解决方案-机器人 -->


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255); font-weight: bold; text-align: left;">
                    {{ $t('IndustryIndex.title1') }}<br>
                    {{ $t('IndustryIndex.title2') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-16" style="color: rgb(255, 255, 255); max-width: 588px; text-align: left;">
                    {{ $t('IndustryIndex.description') }}
                </p>


            </div>





        </div>

    </div>


    <div class="div-spacer-h288"></div>
    <div class="h-c">
        <div class="v-c">
            <!-- <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/机器人狗.jpg')"
                style="max-width: 621px;height: 351px;" /> -->
            <VideoPlayer 
                :video-thumbnail="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/机器人狗.jpg')"
                :video-source="require('@/assets/images/官网开发素材/工规产品/行业视频.mp4')"
                :style="isMobile ? 'max-width: 621px' : 'max-width: 621px; height: 351px'"
            />
            <div v-show="!isMobile" style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: fit-content;
            width: fit-content;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); max-width: 410px; text-align: left;">
                    {{ $t('IndustryIndex.productTitle') }}<br>
                    {{ $t('IndustryIndex.scenarioTitle1') }}<span style="color: #FF6100;">{{ $t('IndustryIndex.scenarioTitle2') }}</span>
                </h3>

                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); font-weight: bold; text-align: left;">
                    <li>{{ $t('IndustryIndex.scenarios.lowSpeedVehicle') }}</li>
                    <li>{{ $t('IndustryIndex.scenarios.smartRobot') }}</li>
                    <li>{{ $t('IndustryIndex.scenarios.agriculture') }}</li>
                    <li>{{ $t('IndustryIndex.scenarios.engineering') }}</li>
                    <li>{{ $t('IndustryIndex.scenarios.uav') }}</li>
                    <li>{{ $t('IndustryIndex.scenarios.port') }}</li>
                    <li>{{ $t('IndustryIndex.scenarios.more') }}</li>
                </ul>






            </div>
            <div v-show="isMobile" style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            
            padding: 0px;
            max-width: 459px;
            max-height: fit-content;
            width: fit-content;

            ">
            <div class="div-spacer-h80"></div>
                <h3 class="text-size-48" style="text-wrap: nowrap;">
                    {{ $t('IndustryIndex.series.cxgy10.title') }}<br>
                    {{ $t('IndustryIndex.series.cxgy10.subtitle') }}
                </h3>
                <h3 class="text-size-48" style="text-wrap: nowrap; color: rgb(255, 97, 0);">
                    {{ $t('IndustryIndex.series.cxgy10.scenarioTitle') }}
                </h3>
                <p class="text-size-16">{{ $t('IndustryIndex.series.cxgy10.description') }}</p>






            </div>



        </div>
    </div>

   


    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: center;">
                    {{ $t('IndustryIndex.series.cxgy10.title') }}<br>
                    {{ $t('IndustryIndex.series.cxgy10.subtitle') }}
                </h3>


            </div>

        </div>

    </div>

    <div class="div-spacer-h48"></div>


    <div class="h-c">




        <div class="v-c">
            <div class="g-c-2-1-1" style="justify-items: center;justify-content: center;gap: 16px;">
                <router-link to="/product/industry/cxgy10">
                    <ProductCard :str1="$t('IndustryIndex.products.cxgy10.title')" :str2="$t('IndustryIndex.products.cxgy10.desc')"
                        :src="require('@/assets/images/官网开发素材/工规产品/CXGY-10_IMU蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />
                </router-link>

                <router-link to="/product/industry/cxgy20">
                    <ProductCard :str1="$t('IndustryIndex.products.cxgy20.title')" :str2="$t('IndustryIndex.products.cxgy20.desc')"
                        :src="require('@/assets/images/官网开发素材/工规产品/CXGY-20_VRU蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />
                </router-link>
                <router-link to="/product/industry/cxgy30">
                    <ProductCard :str1="$t('IndustryIndex.products.cxgy30.title')" :str2="$t('IndustryIndex.products.cxgy30.desc')"
                        :src="require('@/assets/images/官网开发素材/工规产品/CXGY-30_AHRS蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />
                </router-link>
                <router-link to="/product/industry/cxgy50">
                    <ProductCard :str1="$t('IndustryIndex.products.cxgy50.title')" :str2="$t('IndustryIndex.products.cxgy50.desc')"
                        :src="require('@/assets/images/官网开发素材/工规产品/CXGY-50_INS-BOX蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" />
                </router-link>

            </div>

        </div>


    </div>


    <div class="div-spacer-h60"></div>
    <div class="div-spacer-h60"></div>
    <div class="h-c">
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: center;">
                    {{ $t('IndustryIndex.series.cxgy100.title') }}<br>
                    {{ $t('IndustryIndex.series.cxgy100.subtitle') }}
                </h3>


            </div>

        </div>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c" style="gap: 16px;">
        <div class="v-c" style="justify-content: center;gap: 16px;">
            <router-link to="/product/industry/cxgy110">
                <ProductCard :str1="$t('IndustryIndex.products.cxgy110.title')" :str2="$t('IndustryIndex.products.cxgy110.desc')"
                    :src="require('@/assets/images/官网开发素材/工规产品/CXGY-110_IMU蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />
            </router-link>
            <router-link to="/product/industry/cxgy150">
                <ProductCard :str1="$t('IndustryIndex.products.cxgy150.title')" :str2="$t('IndustryIndex.products.cxgy150.desc')"
                    :src="require('@/assets/images/官网开发素材/工规产品/CXGY-150_INS-BOX双天线pbox蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />
            </router-link>
        </div>

    </div>

    <div class="div-spacer-h60"></div>
    <div class="div-spacer-h60"></div>
    <div class="h-c">
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: center;">
                    {{ $t('IndustryIndex.series.beidou.title') }}
                </h3>


            </div>

        </div>

    </div>
    <div class="div-spacer-h48"></div>



    <div class="v-c">
        <div class="g-c-2-1-1" style="justify-items: center;justify-content: center;gap: 16px;">
            <router-link to="/product/industry/cxgyb10">
                <ProductCard :str1="$t('IndustryIndex.products.cxgyb10.title')" :str2="$t('IndustryIndex.products.cxgyb10.desc')"
                    :src="require('@/assets/images/官网开发素材/工规产品/单北斗GNSS蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />
            </router-link>
            <router-link to="/product/industry/cxgyb110">
                <ProductCard :str1="$t('IndustryIndex.products.cxgyb110.title')" :str2="$t('IndustryIndex.products.cxgyb110.desc')"
                    :src="require('@/assets/images/官网开发素材/工规产品/小夜壶蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />
            </router-link>
            <router-link to="/product/industry/cxgyb130">
                <ProductCard :str1="$t('IndustryIndex.products.cxgyb130.title')" :str2="$t('IndustryIndex.products.cxgyb130.desc')"
                    :src="require('@/assets/images/官网开发素材/工规产品/单北斗蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />
            </router-link>
            <router-link to="/product/industry/cxgyb120">
                <ProductCard :str1="$t('IndustryIndex.products.cxgyb120.title')" :str2="$t('IndustryIndex.products.cxgyb120.desc')"
                    :src="require('@/assets/images/官网开发素材/工规产品/车载定位终端蓝色背景.jpg')"
                    style="min-height: 228px;min-width: 304px;" />
            </router-link>

        </div>

    </div>


    <div class="div-spacer-h288"></div>

</template>



<style scoped>
a {

    width: 100% !important;
    height: 100%;

}


.banner {

    background-image: url("@/assets/images/官网开发素材/banner/行业.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}

a {
    width: fit-content;
}
</style>



<script setup>
import VideoPlayer from '@/components/VideoPlayer.vue';

// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ProductCard from '@/components/ProductCard.vue';
import PictureCard from '@/components/PictureCard.vue';
const items2 = [
    { image: require('@/assets/images/官网开发素材/工规产品/CXGY-10_IMU蓝色背景.jpg'), title: 'Title 1', description: 'Description 1' },
    { image: require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=成本低.svg'), title: 'Title 2', description: 'Description 2' },
    { image: require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车1.jpg'), title: 'Title 3', description: 'Description 3' },

];



import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()


</script>